import "../app.js";
import axios from "axios";
import validator from "validator";
import IMask from "imask";
import Alpine from "alpinejs";
import Noty from 'noty';

const adressBtn = document.querySelector(".header__adress-box");
const adressPopup = document.querySelector(".info-popup");
const closeBtn = document.querySelector(".info-popup__close");
const inputMenu = document.getElementById("menu-input");
const mobileMenu = document.querySelector(".header__menu-mobile");
const linksMobile = document.querySelectorAll(".menu__link");
const name = document.querySelector("#name");
const taxpayerName = document.querySelector("#taxpayer-name");
const taxpayerId = document.querySelector("#taxpayer-id");
const taxCheckboxes = document.querySelectorAll(
    ".tax-deduction__checkbox-input"
);
const phone = document.querySelector("#phone");
const taxDateInput = document.querySelector(".tax-deduction__input-date");
const formInputs = document.querySelectorAll(".tax-deduction__input");
const searchInput = document.querySelector(".search");
const doctorCardTemplate = document.querySelector("[data-doctor-template]");
const doctorsContainer = document.querySelector("#doctor-found");
const taxPopupBtns = document.querySelectorAll(".tax-popup");
const taxPopup = document.querySelector(".tax-deduction__form-wrapper");
const taxPopupClose = document.querySelector(".tax-deduction__close");
const taxForm = document.querySelector("#tax-form");
const taxCheckboxLabels = document.querySelectorAll(
    ".tax-deduction__checkbox-label"
);
const topIcon = document.getElementById("top-icon");

// search
let doctors = [];

if (adressBtn !== undefined && adressBtn !== null) {
    adressBtn.addEventListener("click", () => {
        if (typeof openPopup === "function" && adressPopup !== undefined) {
            openPopup(adressPopup);
        }
    });
}
function openPopup(popup) {
    popup.classList.add("info-popup_open");
}

function closePopup(popup) {
    popup.classList.remove("info-popup_open");
}

if(closeBtn) {
    closeBtn.addEventListener("click", () => {
        closePopup(adressPopup);
    });
}

window.addEventListener("click", (evt) => {
    if (
        evt.target.classList.contains("info-popup") ||
        evt.target.classList.contains("info-popup__close")
    ) {
        closePopup(adressPopup);
    } else if (
        evt.target.classList.contains("tax-deduction__form-wrapper") ||
        evt.target.classList.contains("tax-deduction__close")
    ) {
        closePopup(taxPopup);
    }
});

window.addEventListener("keydown", (evt) => {
    if (evt.keyCode == 27) {
        closePopup(adressPopup);
        closePopup(taxPopup);
    }
});
taxPopupBtns.forEach((btn) => {
    btn.addEventListener("click", (evt) => {
        evt.preventDefault();
        openPopup(taxPopup);
    });
});
taxPopupClose.addEventListener("click", () => {
    closePopup(taxPopup);
});

if(inputMenu !== null) {
    inputMenu.addEventListener("click", () => {
        mobileMenu.classList.toggle("mobile-menu_closed");
        mobileMenu.classList.toggle("mobile-menu_open");
    });
}


linksMobile.forEach((link) => {
    link.addEventListener("click", () => {
        mobileMenu.classList.remove("mobile-menu_open");
        mobileMenu.classList.add("mobile-menu_closed");

        inputMenu.checked = false;
    });
});

// header end


const addError = (elem, placeHolder) => {
    elem.classList.add("invalid");
    elem.setAttribute("placeholder", placeHolder);
    elem.focus();
};
function isAtLeastOneChecked(checkboxes) {
    return [...checkboxes].some((checkbox) => checkbox.checked);
}
taxpayerId.addEventListener("keypress", (e) => {
    let x = e.which || e.keycode;
    if (x >= 48 && x <= 57) return true;
    else return false;
});

const checkForm = () => {
    switch (true) {
        case !isAtLeastOneChecked(taxCheckboxes): {
            taxCheckboxLabels.forEach((label) => {
                label.style.color = "red";
            });
            break;
        }
        default:
            return true;
    }
};

var maskOptions = {
    mask: "+{7}(000)000-00-00",
};
var mask = IMask(phone, maskOptions);

// Удаление ошибки
formInputs.forEach((p) => {
    let item = p;
    p.addEventListener("input", (e) => {
        item.classList.contains("invalid") && item.classList.remove("invalid");
    });
});

taxForm.addEventListener("submit", (evt) => {
    evt.preventDefault();
    if (checkForm()) {
        axios
        .post('/tax-deductions', new FormData(taxForm))
        .then((resp) => {
            closePopup(taxPopup);
            new Noty({
                type: 'success',
                layout: 'center',
                text: 'Данные успешно отправлены! Мы сообщим, как только Ваша форма будет готова.',
                theme: 'flat',
                timeout: 3000,
            }).show();
        })
        .catch(error => {
            let errorMessage = 'Неизвестная ошибка, попробуйте позже'
            if (error.response.status === 422) {
                errorMessage = error.response.data.message;
            }
            new Noty({
                type: 'warning',
                layout: 'center',
                text: errorMessage,
                timeout: 3000,
            }).show();
        });
    }
});

function smoothScrollToTop(duration) {
    let start = null;
    const startPos = window.pageYOffset;
    const diff = -startPos;

    function animate(timestamp) {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const percent = Math.min(progress / duration, 1);

        window.scrollTo(0, startPos + diff * percent);

        if (percent < 1) requestAnimationFrame(animate);
    }

    requestAnimationFrame(animate);
}

topIcon.addEventListener("click", () => {
    smoothScrollToTop(600);
});

// поиск по сайту

if(searchInput !== null) {
    searchInput.addEventListener("input", (evt) => {
        const value = evt.target.value.toLowerCase();
        doctors.forEach((doc) => {
            const isVisible =
                doc.name.toLowerCase().includes(value) ||
                doc.job.toLowerCase().includes(value);
            doc.element.classList.toggle("hide", !isVisible);
            if (value == "") {
                doc.element.classList.add("hide");
            }
        });
    });

}

// axios
//     .get("/search")
//     .then((response) => {
//         const res = response.data.data;
//         doctors = res.map((doc) => {
//             const card = doctorCardTemplate.content.cloneNode(true).children[0];
//             const header = card.querySelector("[data-header]");
//             const job = card.querySelector("[data-job]");
//             header.textContent = doc.name;
//             job.textContent = doc.specialties;
//             card.dataset.link = doc.link;
//             doctorsContainer.append(card);
//             card.classList.add("hide");
//             card.addEventListener("click", () => {
//                 document.location.href = doc.link;
//             });
//             return {
//                 name: doc.name,
//                 job: doc.specialties,
//                 link: doc.link,
//                 element: card,
//             };
//         });
//     })
//     .catch((error) => console.log(error));



